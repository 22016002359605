<template>
<div class="user-view history-view error-view">
  <div class="page-title d-flex justify-content-between align-items-center">
    <h3>Код Ошибок</h3>
    <button
        @click="openModal"
        class="btn btn-primary">Добавить
    </button>
  </div>
    <div class="page-content">
      <div class="table__sh-wrapper ">
        <div class="d-flex mb-2">
          <div class="form-group w-select__group mr-2 position-relative">
            <multiselect
                v-model="selectedPaymentType"
                :options="paymentTypes"
                :multiple="false"
                :searchable="false"
                track-by="name"
                selectLabel=" "
                selectedLabel=" "
                deselectLabel=" "
                label="name"
                placeholder="Все"
            >

            </multiselect>
            <div class="clear-select" @click="clearSelect">
              <img src="../../../public/img/error.png" alt="">
            </div>
          </div>
          <input
              v-model="searchError"
              type="text" class="form-control no-focus-input w-300px"
              placeholder="Поиск"
          >
          <button @click="getErrorList()" class="btn btn-success ml-3">
            Поиск
          </button>
        </div>
        <table class="table__sh w-100 border-0" v-if="errorList.length > 0" style="overflow: inherit">
          <tr class="thead">
            <td style="width: 50px">№</td>
            <td>Тип оплаты</td>
            <td>Код Ошибки</td>
            <td>Описание ошибки</td>
            <td></td>
          </tr>
          <tr v-for="(item,index) in errorList" :key="index">
            <td>{{index + 1}}</td>
            <td>{{getPaymentTypeName(item.paymentType)}}</td>
            <td>{{item.errorCode}}</td>
            <td>{{item.errorDescription}}</td>
            <td style="width: 100px">
              <div class="w-100 d-flex justify-content-center">
                <i class="action-icon" @click="getError(item)">
                  <img src="../../../public/img/icon-pen.png" alt="">
                </i>
              </div>
            </td>
          </tr>
        </table>
        <p v-else class="mt-3">Нет транзации</p>
      </div>

  </div>
  <b-modal v-model="modal"
           :title="update ? 'Изменить' : 'Добавить'"
           hide-footer
           centered
  >
    <div>
      <div class="form-group">
        <label>Тип оплаты
        </label>
        <b-form-select
            v-model="$v.form.paymentType.$model"
            :class="{'is-invalid': $v.form.paymentType.$error}"
            :options="paymentTypes"
            value-field="id"
            text-field="name"
            :disabled="update"
        ></b-form-select>
      </div>

      <div class="form-group">
        <label>Код Ошибки	</label>
        <b-form-input
            v-model="$v.form.errorCode.$model"
            :class="{'is-invalid': $v.form.errorCode.$error}"
            placeholder="Код Ошибки	"
            :disabled="update"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label>Описание ошибки	</label>
        <b-form-input
            v-model="$v.form.errorDescription.$model"
            :class="{'is-invalid': $v.form.errorDescription.$error}"
            placeholder="Описание ошибки	"></b-form-input>
      </div>
            <div class="d-flex justify-content-end">
        <button class="btn btn-primary mr-2" @click="getSubmitError">Сохранить</button>
        <button @click="modal= false" class="btn btn-secondary">Отменить</button>
      </div>
    </div>

  </b-modal>
</div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'


export default {
name: "ErrorCode",
  components:{
    Multiselect
  },
  data(){
  return{
    update:false,
    modal:false,
    errorList:[],
    searchError:null,
    selectedPaymentType: [],
    paymentTypes:[],
    paymenType:null,
    form:{
      id:0,
      paymentType: null,
      errorCode:null,
      errorDescription:"",
    }
    }
  },
  methods: {
    openModal() {
      this.update = false;
      this.modal = true;
      this.resetForm();
    },
    resetForm(){
      this.form.id = 0;
      this.form.paymentType = null;
      this.form.errorDescription = '';
      this.form.errorCode = '';
    },
    async getErrorList(){
      let params = {
        text: this.searchError,
        paymentType:this.selectedPaymentType.id
      }
      try {
        this.showLoader();
        const res = await this.$store.dispatch('getErrorList', params);
        this.errorList = res.result
      }
      catch (e) {
        this.errorNotify(e.response.data.error.message);
      } finally {
        this.hideLoader();
      }
    },
    async getPaymentTypes() {
      try {
        const res = await this.$store.dispatch('getPaymentTypes');
        this.paymentTypes = res.result.paymentTypes;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },
    getPaymentTypeName(paymentId) {
      let item = this.paymentTypes.find(i => i.id == paymentId);
      return item ? item.name : paymentId
    },
    getError(error){
      this.modal = true;
      this.update = true;
      this.form = error
    },
   async getSubmitError() {
     this.$v.$touch();
     if (!this.$v.$invalid) {
       try {
         this.showLoader();
         const res = await this.$http.post('Admin/SubmitErrorDictionary', this.form);
         if (res) {
           await this.getErrorList();
           this.modal = false;
           this.successNotify();
         }
       } catch (e) {
         this.errorNotify(e.response.data.error.message);
       } finally {
         this.hideLoader();
       }
     }
   },
    clearSelect(){
      this.selectedPaymentType = []
      this.getErrorList()
    }

  },
  created(){
    this.getErrorList();
    this.getPaymentTypes();
  },
  watch:{
  'searchError': function (val){
    if (val.length == 0){
      this.getErrorList()
    }
  },
    'selectedPaymentType': function (val){
    if (val != []){
      this.getErrorList()
    }
    }
  },
  validations:{
  form:{
    paymentType:{required},
    errorCode:{required},
    errorDescription:{required},
  }
  }
}
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.w-select__group .multiselect__tags{
  height:37px !important;
  min-height: 37px !important;
}
.w-select__group{
  margin-bottom: 0px;
}
</style>
